var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message my-2",class:{ 'message--self': _vm.isAuthor }},[_c('div',{staticClass:"message__content",class:{
      'message__content--media':
        !_vm.currentMessage.price && _vm.currentMessage.hasAccess,
    }},[(!_vm.isAuthor)?_c('b-avatar',{staticClass:"align-self-end",attrs:{"to":_vm.currentMessage.user.url,"src":_vm.currentMessage.user.avatar,"text":_vm.currentMessage.user.initials,"size":"35px"}}):_vm._e(),_vm._v(" "),(_vm.currentMessage.price && !_vm.currentMessage.hasAccess)?[_c('div',{staticClass:"message__grid",class:{ 'message__grid--end': _vm.isAuthor }},[_c('div',{staticClass:"mb-1 message__media"},[_c('MessageLock',{staticClass:"message__content",attrs:{"message":_vm.currentMessage},on:{"unlock":_vm.onUnlock}})],1),_vm._v(" "),(_vm.currentMessage.message)?_c('div',{staticClass:"message__font message__font--message message__body"},[_vm._v("\n          "+_vm._s(_vm.currentMessage.message)+"\n        ")]):_vm._e()])]:_c('div',{staticClass:"message__grid",class:{ 'message__grid--end': _vm.isAuthor }},[(
          _vm.currentMessage.media &&
          _vm.currentMessage.media.length &&
          _vm.currentMessage.hasAccess
        )?_c('div',{staticClass:"mb-1 message__media"},[(_vm.currentMessage.media && _vm.currentMessage.media.length)?_c('CSwiper',{staticClass:"image-preview__swiper h-100",attrs:{"items":_vm.currentMessage.media,"slider-config":_vm.swiperConfig,"static-view":false},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"slide",fn:function({ itemData } = {}){return [_c('div',{staticClass:"image-preview__image-wrapper"},[_c('AppPostContentCard',{attrs:{"media":itemData,"type":"message","style-config":_vm.videoStyleConfig},nativeOn:{"click":function($event){return _vm.toggleModal(itemData)}}})],1)]}}],null,false,3309893213)}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.currentMessage.items.payment)?_c('div',{staticClass:"body rounded p-2 mx-1 wrap mt-1"},[_c('i18n',{attrs:{"path":'general.' +
            (_vm.currentMessage.items.payment.items.post
              ? _vm.isAuthor
                ? 'tip-message-post-out'
                : 'tip-message-post-in'
              : _vm.isAuthor
              ? 'tip-message-out'
              : 'tip-message-out'),"tag":"div"},scopedSlots:_vm._u([{key:"amount",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$formatAmount(_vm.currentMessage.items.payment.amount))+"\n          ")]},proxy:true},(_vm.currentMessage.items.payment.items.post)?{key:"post",fn:function(){return [_c('b-link',{attrs:{"to":_vm.currentMessage.items.payment.items.post.url}},[_vm._v("\n              "+_vm._s(_vm.$t("general.post"))+"\n            ")])]},proxy:true}:null],null,true)})],1):_vm._e(),_vm._v(" "),(!_vm.isAuthor && !_vm.currentMessage.isRead)?_c('span',{staticClass:"message__dot"}):_vm._e(),_vm._v(" "),(_vm.currentMessage.message)?_c('div',{staticClass:"message__font message__font--message message__body"},[_vm._v("\n        "+_vm._s(_vm.currentMessage.message)+"\n      ")]):_vm._e()])],2),_vm._v(" "),_c('div',{staticClass:"message__time"},[(_vm.isAuthor && !_vm.currentMessage.isRead)?_c('i',{staticClass:"bi-check2 message__icon message__icon--check"}):_vm._e(),_vm._v(" "),(_vm.isAuthor && _vm.currentMessage.isRead)?_c('i',{staticClass:"bi-check2-all message__icon message__icon--check message__icon message__icon--check--all"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message__text"},[_c('span',{staticClass:"message__font message__font--time"},[_vm._v("\n        "+_vm._s(_vm.currentMessage.time)+"\n      ")]),_vm._v(" "),(_vm.currentMessage.price && _vm.isAuthor)?_c('span',{staticClass:"message__font message__font--price"},[_vm._v("\n         $"+_vm._s(_vm.currentMessage.price)+"\n        "+_vm._s(_vm.currentMessage.is_paid
            ? "paid"
            : "not paid yet"))]):_vm._e()])]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.open)?_c('div',{staticClass:"image-preview"},[_c('div',{staticClass:"image-preview__image-wrapper"},[(_vm.previewList && _vm.previewList.length)?_c('CSwiper',{staticClass:"image-preview__swiper",attrs:{"items":_vm.previewList,"slider-config":_vm.swiperConfig,"static-view":false},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"slide",fn:function({ itemData } = {}){return [_c('div',{staticClass:"image-preview__field"},[_c('div',{staticClass:"image-preview__bg",on:{"click":function($event){return _vm.toggleModal(itemData)}}}),_vm._v(" "),_c('b-img',{staticClass:"image-preview__image",attrs:{"src":itemData.type == 0 ? itemData.url.url : itemData.screenshot}})],1)]}}],null,false,2315211213)}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"image-preview__button image-preview__button--close",on:{"click":_vm.toggleModalButton}},[_c('CIcon',{staticClass:"image-preview__icon",attrs:{"name":"close"}})],1)],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }