<template>
  <div class="message my-2" :class="{ 'message--self': isAuthor }">
    <div
      class="message__content"
      :class="{
        'message__content--media':
          !currentMessage.price && currentMessage.hasAccess,
      }"
    >
      <b-avatar
        v-if="!isAuthor"
        :to="currentMessage.user.url"
        :src="currentMessage.user.avatar"
        :text="currentMessage.user.initials"
        size="35px"
        class="align-self-end"
      />

      <template v-if="currentMessage.price && !currentMessage.hasAccess">
        <div class="message__grid" :class="{ 'message__grid--end': isAuthor }">
          <div class="mb-1 message__media">
            <MessageLock
              :message="currentMessage"
              class="message__content"
              @unlock="onUnlock"
            />
          </div>
          <div
            v-if="currentMessage.message"
            class="message__font message__font--message message__body"
          >
            {{ currentMessage.message }}
          </div>
        </div>
      </template>

      <div
        v-else
        class="message__grid"
        :class="{ 'message__grid--end': isAuthor }"
      >
        <div
          class="mb-1 message__media"
          v-if="
            currentMessage.media &&
            currentMessage.media.length &&
            currentMessage.hasAccess
          "
        >
          <CSwiper
            v-if="currentMessage.media && currentMessage.media.length"
            :items="currentMessage.media"
            :slider-config="swiperConfig"
            :static-view="false"
            class="image-preview__swiper h-100"
            @click.stop
          >
            <template #slide="{ itemData } = {}">
              <div class="image-preview__image-wrapper">
                <AppPostContentCard
                  :media="itemData"
                  type="message"
                  :style-config="videoStyleConfig"
                  @click.native="toggleModal(itemData)"
                />
              </div>
            </template>
          </CSwiper>

          <!--          <b-link-->
          <!--            @click="toggleModal(media)"-->
          <!--            v-for="(item, index) in media"-->
          <!--            :key="index"-->
          <!--            class="item"-->
          <!--          >-->
          <!--            <div class="position-relative">-->
          <!--              <b-img-->
          <!--                class="image-message"-->
          <!--                v-if="item.type >= 0"-->
          <!--                :src="item.type == 0 ? item.url.url : item.screenshot"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </b-link>-->
        </div>

        <!--        payment-->
        <div
          v-if="currentMessage.items.payment"
          class="body rounded p-2 mx-1 wrap mt-1"
        >
          <i18n
            :path="
              'general.' +
              (currentMessage.items.payment.items.post
                ? isAuthor
                  ? 'tip-message-post-out'
                  : 'tip-message-post-in'
                : isAuthor
                ? 'tip-message-out'
                : 'tip-message-out')
            "
            tag="div"
          >
            <template v-slot:amount>
              {{ $formatAmount(currentMessage.items.payment.amount) }}
            </template>
            <template
              v-slot:post
              v-if="currentMessage.items.payment.items.post"
            >
              <b-link :to="currentMessage.items.payment.items.post.url">
                {{ $t("general.post") }}
              </b-link>
            </template>
          </i18n>
        </div>

        <span v-if="!isAuthor && !currentMessage.isRead" class="message__dot" />
        <!--        message-->
        <div
          v-if="currentMessage.message"
          class="message__font message__font--message message__body"
        >
          {{ currentMessage.message }}
        </div>
      </div>
    </div>

    <div class="message__time">
      <i
        v-if="isAuthor && !currentMessage.isRead"
        class="bi-check2 message__icon message__icon--check"
      />
      <i
        v-if="isAuthor && currentMessage.isRead"
        class="bi-check2-all message__icon message__icon--check message__icon message__icon--check--all"
      />

      <div class="message__text">
        <span class="message__font message__font--time">
          {{ currentMessage.time }}
        </span>
        <span
          class="message__font message__font--price"
          v-if="currentMessage.price && isAuthor"
        >
          &nbsp;${{ currentMessage.price }}
          {{
            currentMessage.is_paid
              ? "paid"
              : "not paid yet"
          }}</span
        >
      </div>
    </div>

    <transition name="fade">
      <div v-if="open" class="image-preview">
        <div class="image-preview__image-wrapper">
          <CSwiper
            v-if="previewList && previewList.length"
            :items="previewList"
            :slider-config="swiperConfig"
            :static-view="false"
            class="image-preview__swiper"
            @click.stop
          >
            <template #slide="{ itemData } = {}">
              <div class="image-preview__field">
                <div class="image-preview__bg" @click="toggleModal(itemData)" />

                <b-img
                  class="image-preview__image"
                  :src="
                    itemData.type == 0 ? itemData.url.url : itemData.screenshot
                  "
                />
              </div>
            </template>
          </CSwiper>

          <button
            @click="toggleModalButton"
            class="image-preview__button image-preview__button--close"
          >
            <CIcon name="close" class="image-preview__icon" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Message from "@/components/models/Message";
import Media from "@/components/models/Media";
import Payment from "@/components/models/Payment";
import MessageLock from "@/features/containers/post/components/AppMessageLock.vue";
import CSwiper from "@/features/ui/CSwiper.vue";
import AppPostContentCard from "@/features/containers/post/components/AppPostContentCard.vue";
import CIcon from "@/features/ui/CIcon.vue";

const swiperConfig = {
  slidesPerView: 1,
  spaceBetween: 0,
  slidesOffsetBefore: 0,
  speed: 300,
  navigation: true,
  centeredSlides: true,
};

export default {
  name: "ChatMessage",
  components: { CIcon, AppPostContentCard, CSwiper, MessageLock },
  props: ["message"],
  computed: {
    isAuthor() {
      return this.currentMessage?.user?.id == this.$store.state.currentUser.id;
    },
    media() {
      const allMedia = this.currentMessage.media || [];

      return allMedia.slice(0, 1);
    },

    previewList() {
      return this.currentMessage.media?.filter(
        (item) => item.type == this.imageType
      );
    },
  },
  data() {
    return {
      imageType: Media.TYPE_IMAGE,
      open: false,
      swiperConfig,
      activeMedia: [],
      currentMessage: {
        user: {},

        items: {
          payment: {
            items: {},
          },
          media: [],
        },
      },

      videoStyleConfig: {
        "--media-control-display": "", // not hide bottom control panel
        "--media-object-fit": "contain", // video fit by height
      },
    };
  },
  mounted() {
    this.currentMessage = this.message;
  },
  methods: {
    onUnlock(unlockedMessage) {
      this.currentMessage = new Message(unlockedMessage?.data?.message);
      this.$emit('messageChange', this.currentMessage)
    },

    unlock() {
      this.$buyItem({
        type: Payment.TYPE_MESSAGE,
        message: this.message,
        success: (data) => {
          this.message = new Message(data);
        },
      });
    },
    toggleModalButton() {
      this.open = !this.open;
    },
    toggleModal(media) {
      const isImg = media.type == this.imageType;
      if (isImg) {
        this.open = !this.open;
        this.activeMedia = media;
      }
    },
    showModal() {
      // this.$refs["imagePopup"].show();
    },
    hideModal() {
      // this.$refs["imagePopup"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
.message {
  $parent: &;
  position: relative;

  &__content {
    display: flex;
    margin-left: em(15);
    margin-right: em(15);

    //&--media {
    //
    //}
  }

  &__body {
    width: auto;
    margin-left: em(6);
    padding: em(10) em(16) em(11);
    border-radius: em(20);
    background-color: $app-border-gray-1;
    position: relative;
    align-self: center;
  }

  &__media {
    width: 100%;
    min-width: 325px;
    min-height: 240px;
    border-radius: em(20);
    //margin-left: em(6);
    overflow: hidden;
    position: relative;
  }

  &__grid {
    display: grid;
    justify-items: start;
    //width: 100%;
    &--end {
      justify-items: end;
    }
  }

  &__count {
  }

  &__text {
    display: inline-flex;
    align-items: center;
    align-content: center;
  }

  &__time {
    display: flex;
    align-items: center;
    margin-top: em(7);
    margin-left: em(41);
  }

  &__card {
    height: auto;
  }

  //&__dot {
  //  position: absolute;
  //  top: em(0);
  //  right: em(-8);
  //  width: em(6);
  //  height: em(6);
  //  border-radius: 50%;
  //  background-color: $app-blue;
  //}

  &__font {
    color: $app-gray-20;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    &--time {
      color: $app-gray-12;
      font-size: 10px;
    }

    &--price {
      color: $app-gray-20;
      font-weight: 700;
    }
  }

  &__icon {
    &--check {
      display: inline-block;
      color: $app-gray-12;
      width: em(12);

      &--all {
        color: $app-blue;
        width: em(16);
      }
    }

    &--time {
      width: 14px;
      height: 14px;
      max-width: 14px;
      max-height: 14px;
      display: inline-block;
      padding-bottom: 2px;
    }
  }

  &--self {
    #{$parent} {
      &__content {
        flex-direction: row-reverse;
      }

      &__time {
        flex-direction: row-reverse;
        margin-right: em(41);
        margin-left: 0;
      }

      &__body {
        margin-right: em(6);
        margin-left: 0;
        background-color: $app-blue;
      }

      &__font {
        color: white;

        &--time,
        &--price {
          color: $app-gray-12;
        }
      }
    }
  }

  .time {
    font-size: 66%;
  }

  .media {
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.multiple {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-gap: 2px;

      grid-auto-rows: min-content;

      .item {
        > div {
          height: 0;
          padding-bottom: 100%;
          position: relative;
          img {
            overflow: hidden;
            width: 100%;
            height: 100%;
            object-fit: cover;
            //position: absolute;
            top: 0;
            left: 0;
          }
          .count {
            color: $black;
            font-size: 250%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &.single {
      padding: 0 !important;
      .item {
        max-width: 100%;
        > div {
          max-width: 100%;
          img {
            width: 100%;
            height: auto;
            border-radius: $border-radius;
          }
        }
      }
    }
  }
}

.image-preview {
  background-color: rgba($black, 0.5);
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;

  display: block;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    width: 100%;
    height: 90vh;
    //margin-top: 5vh;
  }

  &__swiper {
  }

  &__button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  &__icon {
    width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
  }

  &__field {
    position: relative;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
  }

  &__bg {
    color: gold;
    min-height: 100vh;
  }

  &__image {
    align-self: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image-message {
  min-height: 60px;
}

.count {
  color: $black;
  font-size: 250%;
  position: absolute;
  top: 15px;
  right: 55px;
  transform: translate(-50%, -50%);
}
</style>
